.top-to-btm {
  position: relative;
  cursor: pointer;
}

.iconStyle {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 20;
  width: 45px;
  height: 45px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
}
