.loginSignUpSection {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 70px;
}

.loginSignUpContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 55px;
}

.loginSignUpTabs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.loginSignUpTabs p {
  border: none;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  transition: color 0.3s;
  color: #767676;
  text-decoration: none;
}

.loginSignUpTabs p:hover {
  color: black;
}

.loginSignUpTabs p.active {
  color: black;
}

.loginSignUpTabs p.active::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgb(0, 0, 0);
}

.loginSignUpTabs p:not(.active)::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: rgb(0, 0, 0);
  transition: width 0.2s ease, left 0.2s ease;
}

.loginSignUpTabs p:not(.active):hover::after {
  width: 100%;
  left: 0;
  transition-delay: 0.2s;
}

/* Tab1 Content */

.loginSignUpTabsContentLogin form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 500px;
}

.loginSignUpTabsContentLogin form input {
  padding: 20px;
  border: 2px solid #e4e4e4;
  outline-color: black;
}

.loginSignUpForgetPass {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.loginSignUpForgetPass label {
  display: flex;
  align-items: center;
}

.loginSignUpForgetPass a {
  font-size: 14px;
  color: black;
}

/* custom radio button */

.loginSignUpForgetPass label input {
  appearance: none;
  border: 2px solid #e4e4e4;
  position: relative;
  cursor: pointer;
  outline: none;
  padding: 6px !important;
}

.loginSignUpForgetPass label input:checked {
  border: 2px solid #000000;
}

.loginSignUpForgetPass label input::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease-in-out;
}

.loginSignUpForgetPass label input:checked::after {
  transform: translate(-50%, -50%) scale(1);
}

/* custom radio button end */

.loginSignUpForgetPass p {
  color: #767676;
  font-size: 14px;
}

.loginSignUpTabsContentLogin button {
  padding: 20px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
}

.loginSignUpTabsContentLoginText {
  padding-top: 20px;
}

.loginSignUpTabsContentLoginText p {
  font-size: 14px;
  text-align: center;
  color: #767676;
}

.loginSignUpTabsContentLoginText p span {
  color: black;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
}

/* Tab Content 2 */

.loginSignUpTabsContentRegister form {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 500px;
}

.loginSignUpTabsContentRegister input {
  padding: 20px;
  border: 2px solid #e4e4e4;
  outline-color: black;
}

.loginSignUpTabsContentRegister p {
  color: #767676;
  font-size: 14px;
}

.loginSignUpTabsContentRegister button {
  padding: 20px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
}

@media screen and (max-width: 450px) {
  .loginSignUpSection {
    padding-bottom: 30px;
  }

  .loginSignUpTabsContentLogin form {
    width: 330px;
  }

  .loginSignUpTabsContentRegister form {
    width: 330px;
  }
}

@media screen and (max-width: 320px) {
  .loginSignUpTabsContentLogin form {
    width: 280px;
  }

  .loginSignUpTabsContentRegister form {
    width: 280px;
  }
}
