.limitedProductSection {
  margin: 40px 0;
  padding: 0px 160px;
}

.limitedProductSection h2 {
  text-transform: uppercase;
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}

.limitedProductSection h2 span {
  font-weight: 700;
}

.limitedProductSlider {
  position: relative;
}

.swiper-button {
  display: flex;
  position: absolute;
  top: calc(50% - 12px);
  z-index: 10;
  cursor: pointer;
}

.swiper-button svg {
  background-color: white;
  border: 1px solid #e4e4e4;
  display: flex;
  border-radius: 50%;
  padding: 8px;
  outline: none;
  color: #767676;
}

.image-swiper-button-prev {
  left: -15px;
}

.image-swiper-button-next {
  right: -15px;
}

.lpImageContainer {
  position: relative;
  width: 100%;
  height: 310px;
  overflow: hidden;
  -webkit-transition: all 0.2s cubic-bezier(0.99, 0.99, 0, 0.61);
  transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -o-transition: 0.3s linear;
}

.lpImage {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  object-fit: cover;
}

.lpContainer h4 {
  position: relative;
  top: 320px;
  text-align: center;
  padding: 10px;
  background-color: rgb(253, 253, 253);
  transition: all 0.2s cubic-bezier(0.99, 0.99, 0, 0.61);
  margin: 0 10px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.lpContainer:hover h4 {
  top: 260px;
}

.limitedProductInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.lpCategoryWishlist {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.lpCategoryWishlist p {
  color: #767676;
  font-size: 14px;
}

.productNameInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.productNameInfo a {
  text-decoration: none;
  color: black;
}

.productNameInfo h5 {
  font-weight: 400;
  font-size: 16px;
  text-align: left;
}

.productRatingReviews {
  display: flex;
  gap: 10px;
}

.productRatingReviews span {
  color: #767676;
  font-size: 14px;
}

.productRatingStar {
  display: flex;
  align-items: center;
  gap: 3px;
}

.lpProductImagesCart {
  display: none;
}

@media screen and (max-width: 1210px) {
  .limitedProductSection {
    padding: 0px 60px;
  }

  .lpContainer h4 {
    display: none;
  }

  .lpProductImagesCart {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    padding: 5px;
    z-index: 8;
    color: gray;
  }
}

@media screen and (max-width: 450px) {
  .limitedProductSection {
    padding: 0px 15px;
  }

  .limitedProductSection h2 {
    font-size: 25px;
  }

  .lpImageContainer {
    width: 100%;
    height: 200px;
  }

  .image-swiper-button-prev {
    left: -10px;
  }

  .image-swiper-button-next {
    right: -10px;
  }
}

@media screen and (max-width: 320px) {
  .lpImageContainer {
    width: 100%;
    height: 170px;
  }

  .productNameInfo h5 {
    font-size: 14px;
  }

  .productRatingReviews {
    display: flex;
    gap: 5px;
  }

  .productRatingReviews span {
    font-size: 12px;
  }

  .productRatingStar {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .swiper-button svg {
    background-color: white;
    border: 1px solid #e4e4e4;
    display: flex;
    border-radius: 50%;
    padding: 6px;
    outline: none;
    color: #767676;
  }
}
