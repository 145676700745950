.navBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 160px;
  position: relative;
  z-index: 10;
  background-color: white;
  position: sticky;
  top: 0;
  box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
}

.logoLinkContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoContainer img {
  background: none !important;
}

.linkContainer ul {
  display: flex;
  align-items: center;
  gap: 40px;
}

.linkContainer ul li {
  list-style-type: none;
}

.linkContainer ul li a {
  text-decoration: none;
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 600;
  position: relative;
}

.linkContainer ul li a::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #1b1b1b;
  transition: width 0.3s ease-out;
}

.linkContainer ul li a:hover::after {
  width: 60%;
  transition-delay: 0.1s;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
}

.iconContainer a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: black;
}

.mobile-nav {
  display: none;
}

@media screen and (max-width: 1210px) {
  .navBar {
    display: none;
  }

  .mobile-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 60px;
    position: relative;
    z-index: 10;
    background-color: white;
  }
}

@media screen and (max-width: 450px) {
  .mobile-nav {
    padding: 20px 15px;
  }
}

/* Mobile Menu */

.mobile-menu {
  display: flex;
  flex-direction: column;
  gap: 60px;
  height: 100vh;
  background-color: #fff;
  position: absolute;
  top: 74px;
  right: 0;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(-100%);
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  z-index: 9;
  border-top: 1px solid #e4e4e4;
}

.mobile-menu.open {
  opacity: 1;
  transform: translateY(0);
  overflow: hidden;
}

.mobile-menuSearchBar {
  padding: 20px;
}

.mobile-menuSearchBarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 20px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
}

.mobile-menuSearchBarContainer input {
  border: none;
  width: 100%;
  font-size: 14px;
  outline: none;
}

.mobile-menuSearchBarContainer a {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
}

.mobile-menuList ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #e0e0e0;
}

.mobile-menuList ul li {
  padding: 10px 20px;
}

.mobile-menuList ul li a {
  text-decoration: none;
  color: #1b1b1b;
  font-size: 16px;
  font-weight: 600;
}

.mobile-menuFooter {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.mobile-menuFooterLogin a {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: black;
}

.mobile-menuFooterLogin p {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.mobile-menuFooterLangCurrency {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footerLangCurrency {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.mobile-menuFooterLang {
  display: flex;
  align-items: center;
  gap: 30px;
}

.mobile-menuFooterLang select {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
}

.mobile-menuFooterCurrency {
  display: flex;
  align-items: center;
  gap: 30px;
}

.mobile-menuFooterCurrency select {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
}

.mobile-menuSocial_links {
  display: flex;
  gap: 30px;
  width: 240px;
  margin-bottom: 20px;
}

.mobile-menuSocial_links svg {
  cursor: pointer;
}
