.blogDetailsSectionContainer {
  display: flex;
  flex-direction: column;
  padding: 0px 160px;
  margin-top: 30px;
}

.blogDetailsHeading {
  padding: 0 100px;
  margin-bottom: 50px;
}

.blogDetailsHeading h2 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 10px;
}

.blogDetailsMetaData {
  display: flex;
  gap: 25px;
}

.blogDetailsMetaData span {
  color: #767676;
  font-size: 14px;
  text-transform: uppercase;
}

.blogDetailsFeaturedImg img {
  width: 100%;
  height: 100%;
}

.blogDetailsContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 50px 100px;
}

.blogDetailsContent p {
  font-size: 14px;
  line-height: 24px;
}

.blogDetailsContent h5 {
  font-size: 18px;
  font-weight: 500;
}

.blogDetailsContentBullets {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.blogDetailsContentBulletscontent {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.blogDetailsContentBulletscontent p ul {
  padding-left: 15px;
}

.blogDetailsContentBulletscontent p ol {
  padding-left: 15px;
}

.blogDetailsContentImg {
  display: flex;
  gap: 30px;
}

.blogDetailsContentImg img {
  width: 100%;
  height: 100%;
}

/* share Buttons */

.share-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 100px;
}

.share-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 35px;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.share-button svg {
  margin-right: 15px;
}

.facebook {
  background-color: #3b5998;
}

.facebook:hover {
  background-color: #2d4373;
}

.twitter {
  background-color: #1da1f2;
}

.twitter:hover {
  background-color: #0d95e8;
}

.pinterest {
  background-color: #bd081c;
}

.pinterest:hover {
  background-color: #8c0615;
}

.more {
  background-color: #333;
  width: 53px;
  height: 53px;
  padding: 0;
}

.more svg {
  margin-right: 0;
}

.more:hover {
  background-color: #000;
}

.blogDetailsNextPrev {
  display: flex;
  justify-content: space-between;
  padding: 50px 100px;
}

.blogDetailsNextPrevContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.blogDetailsNextPrevContainer p {
  font-size: 14px;
}

.blogDetailsNextPrevContainerIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.blogDetailsNextPrevContainerIcon p {
  font-size: 14px;
  font-weight: 500;
  position: relative;
}

.blogDetailsNextPrevContainerIcon p::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  border-bottom: 2px solid black;
  transition: width 0.3s ease;
}

.blogDetailsNextPrevContainerIcon p:hover::after {
  width: 50%;
}

.blogDetailsNextPrevContainerIcon2 {
  display: flex;
  align-items: center;
  justify-content: right;
  cursor: pointer;
}

.blogDetailsNextPrevContainerIcon2 p {
  font-size: 14px;
  font-weight: 500;
  position: relative;
}

.blogDetailsNextPrevContainerIcon2 p::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  border-bottom: 2px solid black;
  transition: width 0.3s ease;
}

.blogDetailsNextPrevContainerIcon2 p:hover::after {
  width: 50%;
}

@media screen and (max-width: 1210px) {
  .blogDetailsSectionContainer {
    padding: 0 60px;
    margin-top: 0;
  }

  .blogDetailsHeading {
    padding: 0 50px;
  }

  .blogDetailsContent {
    padding: 50px 50px;
  }

  .share-buttons {
    padding: 0 50px;
  }

  .blogDetailsNextPrev {
    padding: 50px 50px;
  }
}

@media screen and (max-width: 991px) {
  .blogDetailsHeading {
    padding: 0 25px;
  }

  .blogDetailsHeading h2 {
    font-size: 40px;
  }

  .blogDetailsContent {
    padding: 50px 25px;
  }

  .blogDetailsContentImg img {
    width: 48.5%;
  }

  .share-buttons {
    padding: 0 25px;
  }

  .share-button {
    padding: 10px 10px;
  }

  .share-button svg {
    margin-right: 10px;
  }

  .more {
    width: 43px;
    height: 43px;
  }

  .more svg {
    margin-right: 0;
  }

  .blogDetailsNextPrev {
    padding: 50px 25px;
  }
}

@media screen and (max-width: 768px) {
  .blogDetailsHeading {
    padding: 0;
  }

  .blogDetailsContent {
    padding: 50px 0px;
  }

  .blogDetailsContentBullets {
    grid-template-columns: 1fr;
  }

  .share-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .share-button {
    padding: 15px 25px;
  }

  .blogDetailsNextPrev {
    padding: 50px 0;
  }
}

@media screen and (max-width: 450px) {
  .blogDetailsSectionContainer {
    padding: 0px 15px;
  }

  .blogDetailsHeading h2 {
    font-size: 24px;
  }

  .blogDetailsContentImg {
    flex-direction: column;
    gap: 20px;
  }

  .blogDetailsContentImg img {
    width: 100%;
  }

  .share-button {
    padding: 15px 15px;
  }

  .blogDetailsNextPrev {
    flex-direction: column;
    gap: 20px;
  }

  .blogDetailsNextPrevContainer {
    align-items: flex-start;
  }
}
