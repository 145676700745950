.footer {
  padding: 30px 160px;
  background-color: #e4e4e4;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.footer__container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 50px;
  padding-top: 50px;
}

.footer_left {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer_logo_container {
  margin-bottom: 20px;
}

.footer_logo_container img {
  background: none !important;
}

.footer_left p {
  font-size: 14px;
}

.footer_address {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.footer_address strong {
  font-size: 14px;
  font-weight: 600;
}

.social_links {
  display: flex;
  gap: 30px;
  width: 240px;
}

.social_links svg {
  cursor: pointer;
}

.footer_content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.footer_content h5 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.links_container ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.links_container ul li {
  list-style-type: none;
}

.links_container ul li a {
  color: black;
  text-decoration: none;
  font-size: 14px;
  position: relative;
}

.links_container ul li a::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: black;
  transition: width 0.3s ease-out;
}

.links_container ul li a:hover::after {
  width: 80%;
  transition-delay: 0.1s;
}

.footer_right {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.footer_right h5 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.footer_right p {
  font-size: 14px;
}

.footer_right form {
  display: flex;
  margin-top: -10px;
}

.footer_right form input {
  width: 100%;
  padding: 19px 20px;
  border: none;
  outline: none;
}

.footer_right form button {
  padding: 10px 20px;
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
}

.footer_right h6 {
  font-size: 14px;
  font-weight: 500;
}

.paymentIconContainer {
  height: 30px;
  width: 250px;
  margin-top: -10px;
}

.paymentIconContainer img {
  width: 100%;
  height: 100%;
  background: none !important;
}

.footer_bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border-top: 1px solid #cfcdcd;
  padding-top: 30px;
  gap: 20px;
}

.footerLangCurrency {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.footerLang {
  display: flex;
  align-items: center;
  gap: 30px;
}

.footerLang select {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
}

.footerCurrency {
  display: flex;
  align-items: center;
  gap: 30px;
}

.footerCurrency select {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
}

@media screen and (max-width: 1210px) {
  .footer {
    padding: 50px 60px;
  }
  .footer__container {
    grid-template-columns: repeat(2, 1fr);
  }
  .footer_left {
    gap: 10px;
  }
  .footer_content {
    gap: 20px;
  }
  .footer_right {
    gap: 20px;
  }
  .paymentIconContainer {
    width: 200px;
  }
}

@media screen and (max-width: 450px) {
  .footer {
    padding: 20px 15px;
    gap: 30px;
  }
  .footer__container {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .footer_left {
    gap: 10px;
  }
  .footer_content {
    gap: 20px;
  }
  .footer_right {
    gap: 20px;
  }
  .paymentIconContainer {
    width: 150px;
  }
  .paymentIconContainer img {
    height: 40px;
    width: 320px;
  }
  .footerLangCurrency {
    gap: 10px;
  }
}

@media screen and (max-width: 320px) {
  .paymentIconContainer img {
    height: 35px;
    width: 280px;
  }
}
